import React from "react"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"

import HomeHeader from 'components/HomeHeader'
import IntroBlocks from 'components/IntroBlocks'
import Layout from "components/layout"
import colors from "theme/colors"

const IndexPage = ({ pageContext, data, styles }) => {
  const {
    title,
    meta,
    _rawHeader,
    header,
    today
  } = data.sanityHomePage;
  return (
    <Layout
      slug={{current: '/'}}
      pageColor={"#FFFFFF"}
      linkColor={colors.colorHuman200}
      title={title}
      meta={meta || {}}
      settings={data.settings}
      jobs={data.jobs}
      country={pageContext.country}
    >
      <HomeHeader neo={pageContext.country == 'au'} {...header} intro={_rawHeader.copy}></HomeHeader>
      <IntroBlocks blocks={today} />
    </Layout>
  )
}

const styles = () => ({
})

export default connectFela(styles)(IndexPage)

export const query = graphql`
query($country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    allSanityPdfDocument {
        edges {
          node {
            file {
              asset {
                localFile {
                  publicURL
                  name
                  internal {
                    contentDigest
                  }
                }
              }
            }
          }
        }
      }

    sanityHomePage(countrySet: { eq: $country }) {
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      _rawHeader,
      header {
        title
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width:  1440)
          }
        }
      }
      today {
        title
        _rawCopy
        fontcolor
        category
        link {
          ...sanityLinkFrag
        }
        image {
          credits
          asset {
            gatsbyImageData(layout: CONSTRAINED, width:  1440)
          }
        }
      }
    }
  }
`;
