import React from "react"
import { connect as connectFela } from "react-fela"

import IntroBlock from 'components/IntroBlock'
import P from "components/typography/P"
import colors from "theme/colors"

/**
 * Wrapping component that contains a list of IntroBlock
 */
const introBlocks = ({rules, blocks, styles}) => (
  <>
    {blocks.map((block, index) => {
      return <IntroBlock
               key={index}
               title={block.title}
               number={index + 1}
               copy={block._rawCopy}
               link={block.link}
               image={block.image}
               color={(block.fontcolor !== 'Light' ? colors.colorConsole500 : colors.colorCanvas50)}
               pageColor={(block.fontcolor !== 'Light' ? colors.colorHuman200 : colors.colorConsole300)}
               category={block.category} />
    })}
  </>
)

const styles = () => ({})

const IntroBlocks = connectFela(styles)(introBlocks)
export default IntroBlocks
